import axios from 'axios'
// import { Toast, Notify } from "vant";
import store from '@/store'
// import { getToken } from '@/utils/auth'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
    // 创建axios实例
const service = axios.create({
        // axios中请求配置有baseURL选项，表示请求URL公共部分
        baseURL: process.env.VUE_APP_BASE_API,
        // 超时
        timeout: 20000
    })
    // request拦截器
service.interceptors.request.use(
    config => {
        // if (getToken()) {
        //     config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
        // }
        store.commit('showLoading')
        return config
    },
    error => {
        store.commit('hideLoading')
        Promise.reject(error)
    }
)

// 响应拦截器
service.interceptors.response.use(res => {
        const code = res.data.code
        if (code === 401) {
            store.commit('hideLoading')
            store.dispatch('LogOut').then(() => {
                location.reload() // 为了重新实例化vue-router对象 避免bug
            })
        } else if (code !== 200) {
            store.commit('hideLoading')
                // Toast.fail(res.data.msg);
            return Promise.reject('error')
        } else {
            store.commit('hideLoading')
            return res.data
        }
    },
    error => {
        store.commit('hideLoading')
            // Notify({ type: 'warning', message: '请求超时', duration: 5 * 1000 });
        return Promise.reject(error)
    }
)

export default service