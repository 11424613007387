import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout';

Vue.use(Router)

export const constantRoutes = [{
    path: '/',
    component: Layout,
    redirect: '/index',
    children: [{
            path: '/index',
            component: () =>
                import ('@/views/index'),
            name: 'home',
        },
        {
            path: '/abstract',
            name: 'abstract',
            component: () =>
                import ('@/views/abstract'),
            meta: {
                title: '简介'
            },
        },
        {
            path: '/process',
            name: 'process',
            component: () =>
                import ('@/views/process'),
            meta: {
                title: '工艺'
            },
        },
        {
            path: '/contact',
            name: 'contact',
            component: () =>
                import ('@/views/contact'),
            meta: {
                title: '联系我们'
            },
        },
        {
            path: '/product',
            name: 'product',
            component: () =>
                import ('@/views/product'),
            meta: {
                title: '产品'
            },
        },
    ],
}, ]



export default new Router({
    mode: 'history', // 去掉url中的#
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})