module.exports = {
    language: {
        name: "English",
        current: "Current Language"
    },
    navbar: {
        home: "HOME",
        abstract: "BRAND",
        productr: "PRODUCT",
        process: "PROCESS",
        contact: "CONTACT",
    },
    indexText: {
        abstract: {
            text1: "HUMAN BEINGS NEED TOYS<br/>RATHER THAN JUST HAPPY",
            text2: "",
            text3: "About us",
        },
        productr: {
            text1: "About our product",
            text2: "Plaything weary in well doing<br/>toys will play!",
            text3: "We strive to Interesting<br/>dare to play, dare to dream of creation conce",
            text4: "The role of the image, superior quality, quality by hand Create the exclusive character of wet play IPCustomize the exclusive tide play around Move each one to see us."
        },
        process: {
            text1: "Process quality",
            text2: "A perfect outfit<br/>Is like a second skin toys.",
            text3: "To achieve good quality<br/>Process is never trade secrets"
        },
        videoText: "Huuungry ®｜对梦想很饥渴！",
        hotText: "A popular collection"
    },
    abstractText: {
        text1: "",
        text2: 'Huuungry ®, because BE @ RBRICK was widely attention a tide play around the design studio. After 95 the young team, "fill in" for the first time to the industry Many BE @ RBRICK around work, belongs to the industry, works from shape, aesthetics, materials including design concept all strive to BE interesting and different.',
        text3: `Founder SamChee is a BE @ RBRICK collector "Toys, will play" is the core of he has been adhering to the concept.<br/><br/><br/>Sam from small to large, there are many rambling dream childhood because a tram Depending on the play fantasy become brave justice police's uncle, take a heroic posture of the flight Member... Along with the age growth, Sam heart dream role and career change again and again, also want to To become has an amazing superpowers wizard the speed of Rapper of fast and the furious competition Driver, the social elite, business tycoons, mountain wild man... When the light dream reality, we Fantasy become people, mostly we can't touch the role.`,
        text4: "Therefore, Huuungry ®, the birth of tide play into the role of the dream, with all kinds of garments Uniforms and weapons, restore the toy world of virtual life, let them finish the past, not now To our unfinished dream.",
    },
    processText: {
        text1: "",
        text2: "Manual custom BE @ RBRICK around to take an examination of is not just about workInvestigate and exquisite more is its sense of personal and exclusive. Designers and cutting Seam mastery of detail and processing, including weapons weight precision meter Calculate, clothes fit with bearsetc... To get custom around almost The perfect fit.<br /><br />We adhere to the rigorous behind the brand, and trial and error Spirit, we have been proud.",
        text3: "A perfect costume, is like a second skin toys. To achieve good quality, the process is never trade secrets.",
        text4: "We insist on using the traditional handmade craft, 3 d armed with weapons, we are very careful in the material, and in combination On the basis of traditional craft fission innovation unceasingly, make the toy itself step by step, toys, we're going to play it! custom Meaning in addition to enjoy the process of itself, he has become a kind of life attitude, will play toys, rather than just decoration.",
    },
    contactText: {
        text1: "",
        text2: "Huuungry ®, plaything weary in well doing, not toys to play! We strive to interesting, dare to play dare to dream of creation concept, rich character, high quality Handmade quality and creating the exclusive character of wet play IP, move each one to see us.",
        text3: "Chaozhou xiang<br/>bridge inguangdong<br/>provinceOuter ring road north<br/>industrial zone no. 12",
        text4: "",
        text5: "your name",
        text6: "Contact phone number",
        text7: "Please fill out the message content in detail",
        text8: "Confirm to submit",
        text9: "Please complete the form information",
        text10: "Please fill in your mobile phone number correctly",
        text11: "Tips"
    },
    productrText: {
        text1: "HUUUNGRY",
        text2: "Return",
        text3: "No product~",
        text4: "HOT",
        text5: "小程序扫码购买",
        text6: "查看详情"
    },
    footer: {
        column1: {
            title: "About us",
            text1: "Who we are",
            text2: "Process"
        },
        column2: {
            title: "Product",
            text1: "Clothes",
            text2: "Suit",
            text3: "Weapons",
            text4: "The traffic tools",
        },
        column3: {
            title: "Process",
            text1: "The high quality",
            text2: "Traditional manual",
            text3: "Attitude",
            text4: "Conce",
            text5: "Innovation",
        },
        column4: {
            title: "Contact us",
            text1: "CALL : ",
        },

    }
};