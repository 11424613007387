import { getConfig } from "@/api/config"
import { getcategoryList } from "@/api/product"
const config = {
    state: {
        ABOUTUSINFO: "",
        CATELIST: ""
    },
    mutations: {
        SET_CONFIG_ABOUTUS(state, data) {
            state.ABOUTUSINFO = data
        },
        SET_CONFIG_CATE(state, data) {
            state.CATELIST = data
        }
    },
    actions: {
        //联系我们
        GetconfigbyAboutUs({ commit }) {
            return new Promise((resolve, reject) => {
                getConfig("aboutUs").then(res => {
                    resolve(res)
                    commit("SET_CONFIG_ABOUTUS", res.data)
                }).catch(res => {
                    reject(res)
                })
            })
        },
        //产品分类
        Getcate({ commit }) {
            return new Promise((resolve, reject) => {
                getcategoryList().then(res => {
                    resolve(res)
                    commit("SET_CONFIG_CATE", res.data)
                }).catch(res => {
                    reject(res)
                })
            })
        },
    }
}

export default config