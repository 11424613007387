import Cookies from 'js-cookie'
const key = "lang"
const lang = {
    state: {
        LANG: Cookies.get(key) || 1
    },
    mutations: {
        setLang(state, type) {
            Cookies.set(key, type, { expires: 7, })
            state.LANG = type
        }
    }
}

export default lang