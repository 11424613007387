import Vue from 'vue'
import Vuex from 'vuex'
import loading from './modules/loading'
import config from './modules/config'
import lang from './modules/lang'
import getters from './getters'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        loading,
        lang,
        config
    },
    getters
})

export default store