<template>
  <div class="footer">
    <div class="footer-box view_flex_sb">
      <template v-for="(item, index) in footerNav">
        <div class="navColumn" :key="index">
          <h3 class="nav-title">{{ $t(item.title) }}</h3>
          <div class="nav-list">
            <ul>
              <template v-for="(itemC, indexC) in item.content">
                <li :key="indexC">
                  <template v-if="itemC.type != 'img'">
                    <a
                      :class="{ size_16: itemC.type == 'phone' }"
                      @click="router(itemC.path, item.index)"
                      >{{ $t(itemC.name) }}
                      <span v-if="itemC.type == 'phone'">{{
                        aboutUsInfo.phone
                      }}</span>
                    </a>
                  </template>
                  <template v-else>
                    <div class="navImg">
                      <img :src="itemC.url" alt="扫一扫" />
                    </div>
                  </template>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </template>
    </div>
    <div class="copyright view_flex_cc size_12">
      <div class="icon">
        <img src="@/assets/images/beianico.png" />
      </div>
      <div>
        Copyright © 2021-2022
        <a class="color_fff" href="https://huuungry.cn">
          https://huuungry.cn
        </a>
        版权所有
        <a class="color_fff" href="https://beian.miit.gov.cn" target="_blank">
          粤ICP备2021175565号</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
let fun = null;
export default {
  name: "component_name",
  computed: {
    ...mapState({
      aboutUsInfo: (state) => state.config.ABOUTUSINFO,
    }),
  },
  mounted() {
    fun = this.$parent.$children[0].router;
  },
  data() {
    return {
      footerNav: [
        {
          title: "footer.column1.title",
          index: 1,
          content: [
            {
              name: "footer.column1.text1",
              path: "/abstract",
            },
            {
              name: "footer.column1.text2",
              path: "/abstract",
            },
          ],
        },
        {
          title: "footer.column2.title",
          index: 2,
          content: [
            {
              name: "footer.column2.text1",
              path: "/product",
            },
            {
              name: "footer.column2.text2",
              path: "/product",
            },
            {
              name: "footer.column2.text3",
              path: "/product",
            },
            {
              name: "footer.column2.text4",
              path: "/product",
            },
          ],
        },
        {
          title: "footer.column3.title",
          index: 3,
          content: [
            {
              name: "footer.column3.text1",
              path: "/process",
            },
            {
              name: "footer.column3.text2",
              path: "/process",
            },
            {
              name: "footer.column3.text3",
              path: "/process",
            },
            {
              name: "footer.column3.text4",
              path: "/process",
            },
            {
              name: "footer.column3.text5",
              path: "/process",
            },
          ],
        },
        {
          title: "footer.column4.title",
          index: 4,
          content: [
            {
              name: "footer.column4.text1",
              type: "phone",
              path: "/contact",
            },
            {
              name: "二维码",
              type: "img",
              url: require("../../../assets/images/ercode.png"),
            },
          ],
        },
      ],
    };
  },
  methods: {
    router(path, index) {
      fun(path, index);
    },
  },
};
</script>
<style lang="scss" scoped>
.containerView {
  width: 990px;
}

.footer {
  min-width: 1200px;
  background: #13131d;
}

.footer-box {
  width: 990px;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 20px;
}

.navColumn {
  .nav-title {
    font-size: 16px;
    margin-bottom: 40px;
  }
  li a {
    font-size: 12px;
    color: #fff;
  }
  li {
    overflow: hidden;
  }
  a {
    display: block;
    padding-bottom: 20px;
    cursor: pointer;
  }
  color: #fff;
  .navImg {
    width: 90px;
    height: 90px;
  }
}

.copyright {
  height: 40px;
  background: #000;
  color: #fff;
  .icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
</style>
