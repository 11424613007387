<template>
  <div class="header">
    <div class="containerView">
      <div class="header-box view_flex_c">
        <div class="header-logo"></div>
        <div class="header-nav">
          <ul class="nav view_flex_c">
            <template v-for="(item, index) in nav">
              <li class="block" :key="index">
                <a @click="router(item.url, index)">
                  <div
                    class="item view_flex_cc"
                    :class="{ active: active == index }"
                  >
                    {{ $t(item.name) }}
                  </div>
                </a>
                <b></b>
              </li>
            </template>
          </ul>
        </div>
        <div class="language" @click="changeLang">
          <img v-if="langType" src="@/assets/images/zh.svg" />
          <img v-else src="@/assets/images/en.svg" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import Cookies from "js-cookie";
export default {
  name: "headerView",
  created() {
    let cookie_lang = Cookies.get("lang");
    if (cookie_lang) {
      this.langType = cookie_lang == 1 ? true : false;
    }
  },
  mounted() {
    let fullPath = this.$router.history.current.fullPath.split("?")[0];
    let index = this.nav.findIndex((item) => item.url == fullPath);
    this.active = index;
  },
  watch: {
    $route: {
      handler: function (val) {
        let index = this.nav.findIndex((item) => item.url === val.path);
        if (index != -1) {
          this.active = index;
        }
      },
    },
  },
  data() {
    return {
      show: false,
      nav: [
        { name: "navbar.home", url: "/index" },
        { name: "navbar.abstract", url: "/abstract" },
        { name: "navbar.productr", url: "/product" },
        { name: "navbar.process", url: "/process" },
        { name: "navbar.contact", url: "/contact" },
      ],
      active: 0,
      langType: true,
      lang: ["zh", "en"],
    };
  },
  methods: {
    router(path, index, id) {
      let thisPath = this.$router.history.current.path;
      if (path != thisPath) {
        this.$router.push({ path, query: { id } });
        this.active = index;
      }
    },
    changeLang() {
      let lang = this.lang;
      let langType = this.langType;
      this.langType = langType;
      this.$i18n.locale = lang[Number(langType)];
      store.commit("setLang", langType + 1);
      this.langType = !langType;
      //刷新初始化当前页数据
      let initFun = this.$parent.$refs.main.$refs.view.init || function () {};
      Promise.all([
        store.dispatch("GetconfigbyAboutUs"),
        store.dispatch("Getcate"),
        initFun(),
      ]).then(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9;
  min-width: 1200px;
  height: 80px;
  background: #fff;
  box-shadow: 0px 3px 6px rgba(12, 62, 136, 0.1);
  opacity: 0.9;
  .header-box {
    height: 80px;
  }
}

.header-logo {
  width: 169px;
  height: 60px;
  margin-right: 263px;
  background: url("../../../assets/images/logo.png") no-repeat center center;
  background-size: 100% 100%;
  cursor: pointer;
}

.language {
  width: 32px;
  height: 32px;
  margin-left: 44px;
  cursor: pointer;
}

.header-nav,
.nav {
  height: 100%;
  li,
  a {
    color: #000000;
    height: 100%;
    transition: all 0.2s;
    cursor: pointer;
  }
  .item {
    width: 130px;
    height: 100%;
    text-align: center;
  }
  .active {
    background: #000000;
    color: #fff;
  }
  .block {
    position: relative;
    b {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0%;
      background: #000000;
      transition: all 0.5s;
      cursor: pointer;
      z-index: -1;
    }
  }
  .block:hover b {
    height: 100%;
  }
  .block:hover a {
    color: #fff;
  }
}
</style>
