import router from './router'
import store from './store'

router.beforeEach((to, from, next) => {
    // // 判断当前用户是否已拉取完user_info信息
    if (typeof store.getters.aboutUsInfo === 'string' || typeof store.getters.cateList === 'string') {
        store.commit("showMask");
        Promise.all([store.dispatch('GetconfigbyAboutUs'), store.dispatch('Getcate')]).then(() => {
            next();
        })
    } else {
        next()
    }
})

router.afterEach(() => {

})