import request from '@/utils/request'
import store from '@/store'

//获取轮播
export function getbanner() {
    return request({
        url: "/web/banner/list",
        method: 'get',
        params: {
            type: store.getters.lang,
            device: 0
        }
    })
}

//获取平台配置
export function getConfig(key) {
    return request({
        url: "/web/config/list",
        method: 'get',
        params: {
            key,
            type: store.getters.lang
        }
    })
}

// 新增用户留言
export function addmessage(upData) {
    return request({
        url: "/web/message",
        method: 'post',
        data: {
            detail: upData.message,
            name: upData.name,
            phone: upData.phone,
            type: store.getters.lang
        }
    })
}