<template>
  <div>
    <headerView ref="header" />
    <mainView ref="main" />
    <footerView />
  </div>
</template>
<script>
import headerView from "./components/header";
import mainView from "./components/main";
import footerView from "./components/footer";

export default {
  name: "component_name",
  data() {
    return {};
  },
  components: {
    headerView,
    mainView,
    footerView,
  },
};
</script>
<style lang="scss" scoped></style>
