import request from '@/utils/request'
import store from '@/store'

//获取产品分类
export function getcategoryList() {
    return request({
        url: "/web/category/list",
        method: 'get',
        params: {
            type: store.getters.lang
        }
    })
}

//根据分类id获取产品
export function getproductList(data) {
    return request({
        url: "/web/product/list",
        method: 'get',
        params: {
            type: store.getters.lang,
            productCategoryId: data.id,
            pageNum: data.page,
            pageSize: 12
        }
    })
}

//获取热门产品
export function getproductListbyHot(data = {}) {
    return request({
        url: "/web/product/list",
        method: 'get',
        params: {
            type: store.getters.lang,
            isHot: "Y",
            pageNum: data.page || 1,
            pageSize: data.size || 6
        }
    })
}

//获取产品详情
export function getproductDetail(id) {
    return request({
        url: "/web/product/detail",
        method: 'get',
        params: {
            type: store.getters.lang,
            productId: id,
        }
    })
}