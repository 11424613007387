<template>
  <div class="main">
    <v-app>
      <v-main>
        <router-view ref="view" />
      </v-main>
    </v-app>
  </div>
</template>
<script>
export default {
  name: "component_name",
  mounted() {
    let fullPath = this.$router.history.current.fullPath;
    this.fullPath = fullPath;
    window.addEventListener("scroll", this.handleScroll);
  },
  data() {
    return {
      fullPath: "",
    };
  },
  methods: {
    handleScroll(e) {
      let scrollY = e.path[1].window.scrollY;
      let animated = {
        fadeInUp_1: scrollY >= 100,
        fadeInUp_2: scrollY >= 150,
        fadeInUp_3: scrollY >= 200,
        fadeInUp_4: scrollY >= 500,
        fadeInUp_5: scrollY >= 550,
        fadeInUp_6: scrollY >= 900,
        fadeInUp_7: scrollY >= 1500,
        rotateIn_8: scrollY >= 2500,
        fadeInUp_9: scrollY >= 3042,
      };
      this.$refs.view._data.animated = animated;
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  min-width: 1200px;
  min-height: 100vh;
}
</style>
