import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import "./assets/style/common.css"
import "animate.css/animate.min.css";
import './permission'
import animated from 'animate.css';
import VueI18n from "vue-i18n";
import store from './store'
import Cookies from 'js-cookie'
Vue.use(animated)
Vue.use(VueI18n);

Vue.prototype.$imgUrl = "https://img.czjuzhi.com/hunnngry/";
Vue.prototype.$lineUrl = "https://img.czjuzhi.com/";

let lang = "zh";
let cookie_lang = Cookies.get("lang");
if (cookie_lang) {
    lang = cookie_lang == 1 ? "zh" : "en";
}
const i18n = new VueI18n({
    locale: localStorage.getItem("lang") || lang,
    messages: {
        zh: require("./utils/language/zh"),
        en: require("./utils/language/en")
    }
});


Vue.directive('animate', {
    inserted: function(el, binding) {
        // 聚焦元素
        binding.addClass = () => {
            const { top } = el.getBoundingClientRect()
            const h = document.documentElement.clientHeight || document.body.clientHeight
            if (top < h) {
                if (el.className.indexOf(binding.value) == -1) {
                    // 初次还未绑定过，则新增类名(注意：下面单引号中间是一个空格！！！)
                    el.className = binding.value + ' ' + el.className
                }
                if (binding.addClass) {
                    window.removeEventListener('scroll', binding.addClass)
                }
            }
        }
        window.addEventListener('scroll', binding.addClass, true)
        binding.addClass()
    },
    unbind: function(el, binding) {
        if (binding.addClass) {
            window.removeEventListener('scroll', binding.addClass)
        }
    }
})

new Vue({
    router,
    vuetify,
    animated,
    i18n,
    store,
    render: h => h(App)
}).$mount('#app')