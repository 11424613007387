const loading = {
    state: {
        LOADING: false,
        MASK: false,
        time: 0,
        over: 0
    },
    mutations: {
        showLoading(state) {
            state.LOADING = true
            state.time++;
        },
        hideLoading(state) {
            ++state.over;
            if (state.time === state.over) {
                setTimeout(() => {
                    state.LOADING = false
                }, 200);
            }
        },
        showMask(state) {
            state.MASK = true
        },
        hideMask(state) {
            state.MASK = false
        },
    }
}

export default loading