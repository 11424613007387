module.exports = {
    language: {
        name: "中文",
        current: "当前语言"
    },
    navbar: {
        home: "首页",
        abstract: "简介",
        productr: "产品",
        process: "工艺",
        contact: "联系我们",
    },
    indexText: {
        abstract: {
            text1: "人类需要玩具，而不仅仅是快乐",
            text2: "关于我们",
            text3: "关于我们",
        },
        productr: {
            text1: "关于产品",
            text2: "玩物不丧志，玩具就要玩好！",
            text3: "我们力求以<br/>有趣、敢玩、敢做梦的创作理念",
            text4: " 丰富的角色形象，优质的手工品质<br />创造潮玩的专属人物IP<br />定制专属的潮玩周边<br />打动每一个看到我们的人。"
        },
        process: {
            text1: "工艺品质",
            text2: "一套完美的服装<br />犹如玩具的第二层皮肤。",
            text3: "要达到好的品质<br />工艺流程从来都不是行业秘密"
        },
        videoText: "Huuungry ®｜对梦想很饥渴！",
        hotText: "热门收藏"
    },
    abstractText: {
        text1: "品牌介绍",
        text2: "Huuungry®，因BE@RBRICK而被广泛关注的一家潮玩周边设计工作室。95后年轻的团队，为行业首次「填办」了很多BE@RBRICK的周边作品，属于行业首创，作品从造型、美学、材质包括设计理念都力争做到有趣与不同。",
        text3: "创始人SamChee是一位BE@RBRICK收藏爱好者「玩具，就要玩好」是他一直秉承的核心理念。<br /><br />Sam从小到大都有许多漫无边际的梦想，童年时期会因为一部电视剧而幻想变身成为英勇正义的警察叔叔，成为雄姿英发的飞行员...随着年岁增长，Sam心中的梦想角色和职业也反复改变，想要成为拥有超能力的巫师、语速惊人的 Rapper速度与激情的赛车手、社会精英、商业大亨、山村野夫...当现实照亮梦境，我们幻想成为的人，大多数都是我们无法触及的角色。<br /><br /><br />因此，Huuungry®诞生，把潮玩变成梦里的角色，以各类的衣服和武器，还原玩具世界的虚拟生命，让它们完成过去现在、未来我们未完成的梦。",
        text4: " Huuungry®，玩物不丧志，玩具就要玩好！我们力求以有趣、敢玩、敢做梦的创作理念，丰富的角色形象，优质的手工品质，创造潮玩的专属人物 IP，打动每一个看到我们的人。",
    },
    processText: {
        text1: "品牌工艺",
        text2: " 手工定制的BE@RBRICK周边不仅仅在于做工的考究和细腻更在于它的个性化和专属感。设计师与裁缝师对细节的把握与处理，包括武器重量的精准计算，衣服与熊的契合度等...才能让定制的周边近乎 完美合身。<br /><br />我们品牌背后所坚持的严谨缜密，以及敢于试错的工匠精神,我们一直引以为傲。",
        text3: "一套完美的服装，犹如玩具的第二层皮肤。要达到好的品质，工艺流程从来都不是行业秘密。",
        text4: " 我们坚持采用最传统的手工缝制工艺，3D制作手持武器，在选料上我们非常缜密，并会在结合传统工艺的基础上不断地裂变创新，一步步让玩具回归本身的乐趣，玩具就要把它玩好！定制的意义除了自身享受的过程，他已经成为一种生活态度，玩具就要玩好，而不仅仅是摆设。",
    },
    contactText: {
        text1: "联系我们",
        text2: "Huuungry®，玩物不丧志，玩具就要玩好！我们力求以有趣、敢玩、敢做梦的创作理念，丰富的角色形象，优质的手工品质，创造潮玩的专属人物 IP，打动每一个看到我们的人。",
        text3: "广东省潮州市湘桥区<br />外环北路工业区12号",
        text4: "客户留言",
        text5: "您的姓名",
        text6: "联系方式",
        text7: "请填写详细留言内容",
        text8: "确认提交",
        text9: "请完善表单信息",
        text10: "请正确填写手机号",
        text11: "提示"
    },
    productrText: {
        text1: "HUUUNGRY产品",
        text2: "返回",
        text3: "暂无产品哦~",
        text4: "热门收藏",
        text5: "小程序扫码购买",
        text6: "查看详情"
    },
    footer: {
        column1: {
            title: "关于我们",
            text1: "我们是谁",
            text2: "我们的团队"
        },
        column2: {
            title: "产品",
            text1: "衣服",
            text2: "套装",
            text3: "武器",
            text4: "交通工具",
        },
        column3: {
            title: "工艺",
            text1: "高质量",
            text2: "传统手工",
            text3: "态度",
            text4: "理念",
            text5: "创新",
        },
        column4: {
            title: "联系我们",
            text1: "电话：",
        },

    }
};